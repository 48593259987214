var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"white shadow1 rounded",attrs:{"fluid":""}},[_c('v-form',{staticClass:"custom-form",on:{"submit":function($event){$event.preventDefault();return _vm.getProductLedger($event)}}},[_c('v-row',{staticClass:"pb-4",attrs:{"dense":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto","md":"4"}},[_c('v-row',{staticStyle:{"margin-top":"3px","color":"#000","align-items":"center"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}},[_vm._v("Product")]),_c('v-col',{attrs:{"cols":"9"}},[_c('v-combobox',{attrs:{"dense":"","outlined":"","hide-details":"","items":_vm.$store.getters['product/products'],"loading":_vm.$store.getters['product/loadingProducts'],"item-text":"display_text","item-value":"id"},on:{"focus":function($event){return _vm.$store.dispatch('product/getProducts')}},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"auto","md":"5"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{staticStyle:{"margin-top":"3px","color":"#000","align-items":"center"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v("Date From")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(_vm.filter.dateFrom),callback:function ($$v) {_vm.$set(_vm.filter, "dateFrom", $$v)},expression:"filter.dateFrom"}},'v-text-field',attrs,false),on),[_c('v-icon',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v("mdi-calendar-month")])],1)]}}])},[_c('v-date-picker',{model:{value:(_vm.filter.dateFrom),callback:function ($$v) {_vm.$set(_vm.filter, "dateFrom", $$v)},expression:"filter.dateFrom"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{staticClass:"pl-3",staticStyle:{"margin-top":"3px","color":"#000","align-items":"center"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_vm._v("Date To")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(_vm.filter.dateTo),callback:function ($$v) {_vm.$set(_vm.filter, "dateTo", $$v)},expression:"filter.dateTo"}},'v-text-field',attrs,false),on),[_c('v-icon',{attrs:{"slot":"prepend-inner"},slot:"prepend-inner"},[_vm._v("mdi-calendar-month")])],1)]}}])},[_c('v-date-picker',{model:{value:(_vm.filter.dateTo),callback:function ($$v) {_vm.$set(_vm.filter, "dateTo", $$v)},expression:"filter.dateTo"}})],1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"auto","md":"1"}},[_c('v-btn',{staticClass:"text_bg_fave",attrs:{"type":"submit","loading":_vm.loading}},[_vm._v("Search")])],1)],1),_c('v-divider'),(_vm.show)?_c('v-row',[_c('vue-excel-xlsx',{staticClass:"ml-2 mb-2",attrs:{"data":_vm.$store.getters['product/ledgers'],"columns":_vm.columns,"file-name":("Product-Ledger-" + (new Date().getDate()) + "-" + (new Date().toLocaleString(
                'default',
                { month: 'long' }
                )) + "-" + (new Date().getFullYear())),"file-type":'xlsx',"sheet-name":'sheetname'}},[_c('v-btn',[_vm._v(" Download Excel ")])],1),_c('v-col',{staticClass:"d_table",attrs:{"cols":"12","id":"printContent"}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Date")]),_c('th',[_vm._v("Description")]),_c('th',[_vm._v("Rate")]),_c('th',[_vm._v("Stock In")]),_c('th',[_vm._v("Stock Out")]),_c('th',[_vm._v("Stock")])])]),_c('tbody',[_c('tr',[_c('td'),_c('td',[_c('strong',[_vm._v("Opening Stock")])]),_c('td',{attrs:{"colspan":"3"}}),_c('td',{staticClass:"text-right"},[_c('strong',[_vm._v(_vm._s(_vm.$store.getters['product/openingStock']))])])]),_vm._l((_vm.$store.getters['product/ledgers']),function(product,ind){return _c('tr',{key:ind},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(product.date))]),_c('td',[_vm._v(_vm._s(product.description))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(product.rate))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(product.in_quantity))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(product.out_quantity))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(product.stock))])])})],2)]},proxy:true}],null,false,2329120206)})],1)],1):_c('div',{staticClass:"no_result"},[_c('div',{staticClass:"img"})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }